<template>
    <div class="center-all text-center">
        <div>
            <v-img class="mx-auto" width="180" :src="imgLogo"/>
        </div>
        <div class="title mt-9">
            Tus exámenes se cargaron correctamente
        </div>
        <div class="subtitle">
            Pronto recibirás un correo y/o notificación de parte del equipo médico.
        </div>
        <div class="title">
            Gracias para utilizar CxSmart.
        </div>
    </div>
</template>

<script>
export default {
    name: "ExamsFinish"
}
</script>

<style scoped>

.center-all {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
}

.title {
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    color: #FFFFFF;
}

.subtitle {
    font-size: 14px;
    line-height: 16px;
    color: #FFFFFF;
    margin-top: 12px;
    margin-bottom: 42px;
}

</style>